// firebase.js

import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyBss5pe7lBHp6VbVYDNzxtDhgTN_XrYDgU",
  authDomain: "ugc-collector.firebaseapp.com",
  projectId: "ugc-collector",
  storageBucket: "ugc-collector.appspot.com",
  messagingSenderId: "1022869998728",
  appId: "1:1022869998728:web:23537b177ff78b165de33d"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export { auth };
