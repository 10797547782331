import { createStore, combineReducers, applyMiddleware } from "redux";


import thunk from 'redux-thunk'
import { userReducer } from "./reducers/userReducer";
import { contentReducer } from "./reducers/contentReducer";


const reducers = combineReducers({
    user: userReducer,
    content : contentReducer
})


let initialState = []

const middleware = [thunk]

const store = createStore(reducers, initialState, applyMiddleware(...middleware));

export default store;

