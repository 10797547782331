import React, { Fragment, useState } from "react";
import { Menu, Transition } from "@headlessui/react";
import classNames from "classnames";
import {
  Image,
  Video,
  MagnifyingGlass,
  ArrowUp,
  ArrowDown,
  CaretUpDown,
  SlidersHorizontal,
} from "@phosphor-icons/react";
import { useNavigate } from "react-router-dom";
import { SecondaryButton } from "./buttons";

const Filter = (props) => {
  const navigation = useNavigate();
  const [selectedFilterCount, setSelectedFilterCount] = useState(0);

  // alert(JSON.stringify(props.filter))
  console.log(props.filter)
  return (
    <div className="border-b border-gray-200">
      <div className="px-4 py-4 sm:flex sm:items-center sm:justify-between">
      <div className="flex">
        <div className="flex-wrap items-center justify-between sm:flex-nowrap">
          <div className="flex items-center gap-x-5">
            <h3 className="text-base flex-shrink-0 font-medium leading-6 text-slate-900">
              User Generated Content
            </h3>
            {/* UGC counter TODO */}
            {/* <div className="mt-1 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-4">
              <div className="flex items-center text-sm text-slate-500">
                <Image
                  className="mr-1.5 h-4 w-4 flex-shrink-0 text-slate-500"
                  aria-hidden="true"
                />
                <span className="text-sm text-slate-500">0 Photos</span>
              </div>
              <span className="text-sm text-slate-500">|</span>
              <div className="flex items-center text-sm text-slate-500">
                <Video
                  className="mr-1.5 h- w-4 flex-shrink-0 text-slate-500"
                  aria-hidden="true"
                />
                <span className="text-sm text-slate-500">0 Videos</span>
              </div>
            </div> */}
          </div>
          <p className="mt-2 text-sm text-slate-500">
            When creators <span className="text-blue-500">@mention</span> or tag
            your brand, the post will be automatically captured here.
          </p>
        </div>
      </div>
      <div className="mt-3 sm:ml-4 sm:mt-0 mr-2 gap-x-4">
        <label htmlFor="mobile-search-candidate" className="sr-only">
          Search
        </label>
        <label htmlFor="desktop-search-candidate" className="sr-only">
          Search
        </label>
        <div className="flex rounded-md gap-x-2">
          <div className="relative flex-grow focus-within:z-10">
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <MagnifyingGlass
                className="h-5 w-5 text-slate-700"
                aria-hidden="true"
              />
            </div>
            <input
              type="text"
              name="mobile-search-candidate"
              id="mobile-search-candidate"
              className="block w-full rounded-md border-0 py-1.5 pl-10 text-slate-900 ring-1 ring-inset ring-gray-300 placeholder:text-slate-700 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:hidden"
              placeholder="Search"
              value={props.filter.username}
              onChange={(e) => {
                try {
                  props.setFilter((prev) => {
                    return {
                      ...prev,
                      username: e.target.value,
                    };
                  });
                } catch (error) {
                  console.log();
                }
              }}
            />
            <input
              type="text"
              name="desktop-search-candidate"
              id="desktop-search-candidate"
              className="hidden w-full rounded-md border-0 py-1 pl-10 text-sm leading-6 text-slate-900 ring-1 ring-inset ring-gray-300 placeholder:text-slate-700 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:block"
              placeholder="Search username"
              value={props.filter.username}
              onChange={(e) => {
                try {
                  props.setFilter((prev) => {
                    return {
                      ...prev,
                      username: e.target.value,
                    };
                  });
                } catch (error) {
                  console.log();
                }
              }}
            />
          </div>
          <span className="inline-flex items-center justify-center rounded-md bg-blue-50 px-2 py-1 text-sm font-medium text-blue-700 ring-1 ring-inset ring-blue-600/20">
            {selectedFilterCount}
            <SlidersHorizontal className="ml-1 h-5 w-5 text-blue-700" aria-hidden="true" />
          </span>
          <Menu>
            <Menu.Button>                
            <SecondaryButton>
              {/* {selectedFilterCount > 0 && ( */}
              <div className="flex items-center">
                <span className="text-slate-700">Filter</span>
                <CaretUpDown className="h-4 w-4 text-slate-700" aria-hidden="true" />
              </div>
              {/* )} */}
            </SecondaryButton>
            </Menu.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-8 top-20 z-10 w-40 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none bg-opacity-90">
                <Menu.Item>
                  {({ active }) => (
                    <div
                      className={classNames(
                        active ? "bg-gray-50 rounded-md" : "",
                        "flex mx-2 items-center justify-between px-2 py-2"
                      )}
                    >
                      <label
                        htmlFor={"Photos"}
                        className="flex items-center gap-x-3 cursor-pointer"
                      >
                        <input
                          id={"Photos"}
                          aria-describedby={`${"Photos"}-description`}
                          name={"photos"}
                          type="checkbox"
                          className="h-4 w-4 rounded border border-gray-300 text-blue-600 focus:ring-transparent"
                          checked={props.filter.photos}
                          onChange={(e) => {
                            try {
                              props.setFilter((prev) => {
                                const newPhotosValue = e.target.checked;
                                setSelectedFilterCount((prevCount) =>
                                  prevCount + (newPhotosValue ? 1 : -1)
                                );
                                return {
                                  ...prev,
                                  photos: newPhotosValue
                                };
                              });
                            } catch (error) {
                              console.log();
                            }
                          }}
                        />
                        <Image
                          className="text-slate-700 h-4 w-4 flex-shrink-0"
                          aria-hidden="true"
                        />
                        <span className="text-sm text-slate-800">
                          {"Photos"}
                        </span>
                      </label>
                    </div>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <div
                      className={classNames(
                        active ? "bg-gray-50 rounded-md" : "",
                        "flex mx-2 items-center justify-between px-2 py-2"
                      )}
                    >
                      <label
                        htmlFor={"Videos"}
                        className="flex items-center gap-x-3 cursor-pointer"
                      >
                        <input
                          id={"Videos"}
                          aria-describedby={`${"Videos"}-description`}
                          name={"videos"}
                          type="checkbox"
                          className="h-4 w-4 rounded border border-gray-300 text-blue-600 focus:ring-transparent"
                          checked={props.filter.videos}
                          onChange={(e) => {
                            try {
                              props.setFilter((prev) => {
                                const newVideosValue = e.target.checked;
                                setSelectedFilterCount((prevCount) =>
                                  prevCount + (newVideosValue ? 1 : -1)
                                );
                                return {
                                  ...prev,
                                  videos : newVideosValue
                                };
                              });
                            } catch (error) {
                              console.log();
                            }
                          }}
                        />
                        <Video
                          className="text-slate-700 h-4 w-4 flex-shrink-0"
                          aria-hidden="true"
                        />
                        <span className="text-sm text-slate-800">
                          {"Videos"}
                        </span>
                      </label>
                    </div>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <div
                      className={classNames(
                        active ? "bg-gray-50 rounded-md" : "",
                        "flex mx-2 items-center justify-between px-2 py-2"
                      )}
                    >
                      <label
                        htmlFor={"sort"}
                        className="flex items-center gap-x-3 cursor-pointer"
                      >
                        <input
                          id={"sort"}
                          aria-describedby={`${
                            props?.filter?.newest ? "Oldest" : "Newest"
                          }-description`}
                          name={props?.filter?.newest ? "Oldest" : "Newest"}
                          type="checkbox"
                          className="h-4 w-4 rounded border border-gray-300 text-blue-600 focus:ring-transparent"
                          checked={props.filter.newest}
                          onChange={(e) => {
                            try {
                              props.setFilter((prev) => {
                                return {
                                  ...prev,
                                  newest : e.target.checked
                                }
                              })
                            } catch (error) {
                              console.log()
                            }
                          }}
                        />
                        {props?.filter?.newest ? (
                          <ArrowDown
                            className="text-slate-700 h-4 w-4 flex-shrink-0"
                            aria-hidden="true"
                          />
                        ) : (
                          <ArrowUp
                            className="text-slate-700 h-4 w-4 flex-shrink-0"
                            aria-hidden="true"
                          />
                        )}

                        <span className="text-sm text-slate-800">
                          {props?.filter?.newest ? "Oldest" : "Newest"}
                        </span>
                      </label>
                    </div>
                  )}
                </Menu.Item>
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
      </div>
    </div>
    </div>
  );
};
export default Filter;

// {/* <div className="border-b border-gray-200 px-4 py-5 sm:px-6">
// <div className="-ml-6 -mt-4 flex flex-wrap items-center justify-between sm:flex-nowrap">
//     <div className="ml-4 mt-4">
//     <h3 className="text-base font-semibold leading-6 text-slate-900">User Generated Content</h3>
//     <p className="mt-1 text-sm text-slate-500">
//         When creators <span className='text-blue-500'>@mention</span> or tag your brand in an Instagram post, reel, or story, the asset will be automatically captured here for you to download.
//     </p>
//     </div>
//     {/* <div className="ml-4 mt-4 flex-shrink-0">
//     <PrimaryButton
//     isDisabled={false}
//     // clickHandler={}
// >
//     Upload
//     </PrimaryButton>
//     </div> */}
// </div>
// </div> */}
