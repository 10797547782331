import { useSelector } from "react-redux";
import { SecondaryButton } from "./buttons";
import toast from "react-hot-toast";
import {
    Coin,
    Clock, 
    Warning,
  } from "@phosphor-icons/react";

export const SubscriptionStatusBanner = function (props) {

    const userState = useSelector(state => state.user);
    if (userState.user?.stripeSubscriptionStatus) {
        if (userState.user?.stripeSubscriptionStatus === "trialing") {
            return (<>
            <div className="rounded-md bg-blue-50 p-4 m-4">
                <div className="flex items-center">
                    <div className="flex-shrink-0">
                    <Clock className="h-5 w-5 text-blue-400" aria-hidden="true" />
                    </div>
                    <div className="ml-3 flex-1 flex items-center md:flex md:justify-between">
                    <p className="text-sm text-blue-700">{userState.user.posts ? userState.user.posts : 0}/25 posts saved. Please consider subscribing to prevent losing access.</p>
                        <SecondaryButton
                            isDisabled={false}
                            clickHandler={() => {
                            //   alert(JSON.stringify(user.data));
                            props.setOpen(true)
                            }}
                        >
                            Subscribe
                        </SecondaryButton>
                    </div>
                </div>
            </div>
          </>)
        }
        else if (userState.user?.stripeSubscriptionStatus === "active") {
            return (<>
                <div className="rounded-md bg-blue-50 p-4 m-4">
                    <div className="flex items-center">
                        <div className="flex-shrink-0">
                        <Coin className="h-5 w-5 text-blue-400" aria-hidden="true" />
                        </div>
                        <div className="ml-3 flex-1 flex items-center md:flex md:justify-between">
                        <p className="text-sm text-blue-700">You are currently on the Pro plan. </p>
                        <p className="mt-3 text-sm md:ml-6 md:mt-0">
                            <a href="https://billing.stripe.com/p/login/bIYdTZcPW1yW2YgcMM" target='_blank' className="whitespace-nowrap font-medium text-blue-700 hover:text-blue-600">
                            Edit Billing
                            <span aria-hidden="true"> &rarr;</span>
                            </a>
                        </p>
                        </div>
                    </div>
                </div>
              </>)
        }
        else{
            return (<>
                <div className="rounded-md bg-red-50 p-4 m-6">
                    <div className="flex items-center">
                        <div className="flex-shrink-0">
                        <Warning className="h-5 w-5 text-red-400" aria-hidden="true" />
                        </div>
                        <div className="ml-3 flex-1 flex items-center md:flex md:justify-between">
                        <p className="text-sm text-red-700">Subscription Expired! Please subscribe to continue services</p>
                            <SecondaryButton
                                isDisabled={false}
                                clickHandler={() => {
                                //   alert(JSON.stringify(user.data));
                                    props.setOpen(true)
                                }}
                                >
                                Subscribe
                            </SecondaryButton>
                        </div>
                    </div>
                </div>
              </>)
        }
    }
    else {
        return (
            <>
            some
            </>
        )
    }
};