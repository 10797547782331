import React, { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import classNames from "classnames";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom"; // Import NavLink and useLocation
import {
  House,
  SquaresFour,
  CreditCard,
  SignOut,
  X,
  ChatsCircle,
  Info,
  List,
  ArrowArcRight,
  ArrowDownLeft,
  ArrowDownRight,
  ArrowRight,
} from "@phosphor-icons/react";
import { auth } from "../firebase";
import { FacebookButton, SecondaryButton } from "./buttons";
import { FacebookAuthProvider, signInWithPopup, unlink } from "firebase/auth";
import { toast } from "react-hot-toast";
import TrialModal from "./trialModal";
import { useDispatch, useSelector } from "react-redux";
import { loginAction, logout, visit } from "../actions/userAction";
import { SubscriptionStatus } from "./SubscriptionStatus";
import http from "../lib/http";

const navigation = [
  { name: "Account", href: "/", icon: House },
  { name: "Content", href: "/content", icon: SquaresFour },
];

const accountNavigation = [{ name: "Billing", href: "https://billing.stripe.com/p/login/bIYdTZcPW1yW2YgcMM", target:"_blank", icon: CreditCard }];

const teams = [
  {
    id: 1,
    name: "Instagram",
    href: "#",
    iconUrl: "./images/InstagramLogo.webp",
    current: false,
  },
];

const Sidebar = () => {
  const navigate = useNavigate();
  const provider = new FacebookAuthProvider();
  provider.addScope("email");
  provider.addScope("instagram_basic")
  provider.addScope("business_management");
  provider.addScope("pages_manage_metadata");
  provider.addScope("pages_read_engagement");
  provider.addScope("pages_show_list")
  provider.addScope("instagram_manage_comments")
  // provider.addScope("instagram_basic");
  function handleSignIn() {
    dispatch(visit(true));
    signInWithPopup(auth, provider)
      .then((result) => {
        // The signed-in user info.
        const user = result.user;
        // This gives you a Facebook Access Token. You can use it to access the Facebook API.
        const credential = FacebookAuthProvider.credentialFromResult(result);
        const accessToken = credential.accessToken;
        console.log(accessToken);
        dispatch(loginAction(user, accessToken));
      })
      .catch((error) => {
        // Handle Errors here.
        if (error && error.code == "auth/user-cancelled") {
          dispatch(loginAction(null, null, "Make sure, your facebook account has a page, and is connected to instagram business account"))
        }
        dispatch(loginAction())
        console.log(error);
        // ...
      });
  }

  const dispatch = useDispatch();
  const [state, setUser] = useState([]);
  const userState = useSelector((state) => state.user);
  const [loading, setLoading] = useState(true);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const location = useLocation(); // Get the current location
  const [TrialModalOpen, setTrialModalOpen] = useState(false);

  return (
    <div>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50 lg:hidden"
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-900/80" />
          </Transition.Child>

          <div className="fixed inset-0 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                    <button
                      type="button"
                      className="-m-2.5 p-2.5"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <X className="h-6 w-6 text-white" aria-hidden="true" />
                    </button>
                  </div>
                </Transition.Child>
                {/* Sidebar component, swap this element with another sidebar if you like */}
                <div className="flex grow flex-col 4 overflow-y-auto bg-white px-6 pb-2">
                  <div className="flex h-16 shrink-0 items-center">
                    <img
                      className="h-5 w-auto"
                      src="/images/YugeLogo02.png"
                      alt="Yuge Logo"
                    />
                  </div>
                  <nav className="flex flex-1 flex-col">
                    <ul role="list" className="flex flex-1 flex-col gap-y-7">
                      <li>
                        <ul role="list" className="-mx-2 space-y-1">
                          {navigation.map((item) => (
                            <li key={item.name}>
                              <NavLink

                                to={item.href}
                                className={classNames(
                                  item.current
                                    ? "bg-gray-10 text-blue-600"
                                    : "text-white hover:text-blue-600 hover:bg-white",
                                  "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-medium"
                                )}
                              >
                                <item.icon
                                  className={classNames(
                                    item.current
                                      ? "text-blue-600"
                                      : "text-white group-hover:text-blue-600",
                                    "h-6 w-6 shrink-0"
                                  )}
                                  aria-hidden="true"
                                />
                                {item.name}
                              </NavLink>
                            </li>
                          ))}
                        </ul>
                      </li>
                      <li>
                        <div className="text-xs font-medium leading-6 text-white">
                          App Integrations
                        </div>
                        <ul role="list" className="-mx-2 mt-2 space-y-1">
                          {teams.map((team) => (
                            <li key={team.id}>
                              <a
                                href={team.href}
                                className="group flex items-center gap-2 p-2 text-sm font-medium"
                              >
                                {team.iconUrl && (
                                  <img
                                    src={team.iconUrl}
                                    alt={team.name}
                                    className="w-8 h-8 rounded-full"
                                  />
                                )}
                                <span className="truncate">{team.name}</span>
                                {team.id !== "1" && (
                                  <span className="inline-flex items-center rounded-full bg-gray-100 px-2 py-1 text-xs font-medium text-slate-600 ring-1 ring-inset ring-gray-500/10">
                                    Coming Soon
                                  </span>
                                )}
                              </a>
                            </li>
                          ))}
                        </ul>
                      </li>
                    </ul>
                  </nav>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className="flex grow flex-col gap-y-4 overflow-y-auto px-6 py-2 gradient-sidebar">
          <div className="flex h-16 justify-between items-center border-b-2 border-dotted border-blue-100/25">
            <img
              className="h-5 w-auto inverted-logo"
              src="/images/YugeLogo02.png"
              alt="Yuge Logo"
            />
            {/* TO DO -- CHANGE LOG WIDGET  */}
            <div>
              <ChatsCircle
                className="h-6 w-6 text-white hover:text-blue-600 hover:cursor-pointer hover:duration-300 hover:ease-in-out"
                aria-hidden="true"
                onClick={() => window.$crisp.push(["do", "chat:open"])}
              />
            </div>
          </div>
          <nav className="flex flex-1 flex-col">
            <ul role="list" className="flex flex-1 flex-col gap-y-6">
              <li>
                <ul
                  role="list"
                  className="-mx-2 space-y-1 pb-4 border-b-2 border-dotted border-blue-100/25"
                >
                {navigation.map((item) => (
                  <li key={item.name}>
                    <NavLink
                      to={item.href}
                      className="group flex items-center gap-x-3 rounded-md p-2 text-sm font-medium text-white hover:bg-blue-100 hover:text-blue-600 hover:duration-50"
                    >
                      <item.icon
                        className="transition-all duration-300 h-6 w-6 shrink-0 group-hover:translate-x-2"
                        aria-hidden="true"
                      />
                      <span className="transition-all duration-300 group-hover:translate-x-2">
                        {item.name}
                      </span>
                      <ArrowRight className="h-6 w-6 shrink-0 transform -translate-x-12 group-hover:translate-x-0 opacity-0 group-hover:opacity-100 transition-all duration-300" />
                    </NavLink>
                  </li>
                ))}
                </ul>
              </li>
              <li>
                <div className="text-xs font-medium leading-6 text-white">
                  App Integrations
                </div>
                <ul
                  role="list"
                  className="-mx-2 mt-2 space-y-1 pb-4 border-b-2 border-dotted border-blue-100/25"
                >
                  {teams.map((team) => (
                    <li key={team.name}>
                      <a
                        href={team.href}
                        className={classNames(
                          team.current
                            ? "text-blue-600"
                            : "text-white items-center",
                          "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-medium"
                        )}
                      >
                        <span
                          className={classNames(
                            team.current
                              ? "text-blue-600 border-blue-600"
                              : "text-white border-blue-200 group-hover:border-blue-600 group-hover:text-blue-600",
                            "flex h-8 w-8 shrink-0 items-center justify-center rounded-lgp-0.5 text-[0.625rem] font-medium"
                          )}
                        >
                          <img src={team.iconUrl} alt={team.name} />
                        </span>
                        <span className="truncate">{team.name}</span>
                        <div className="ml-auto">
                          {team.id !== 1 && (
                            <span className="inline-flex items-center rounded-md px-2 py-1 text-xs font-medium text-slate-600 ring-1 ring-inset ring-gray-500/10">
                              Coming Soon
                            </span>
                          )}
                          {userState.data
                            ? team.id === 1 && (
                              <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                                Connected
                              </span>
                            )
                            : team.id === 1 && (
                              <span className="inline-flex items-center rounded-md bg-red-100 px-2 py-1 text-xs font-medium text-red-500 ring-1 ring-inset ring-red-200">
                                Connect
                              </span>
                            )}
                        </div>
                      </a>
                    </li>
                  ))}
                </ul>
              </li>          
              <li>
                <ul
                  role="list"
                  className="-mx-2 space-y-1 pb-4 border-b-2 border-dotted border-blue-100/25"
                >
                  {accountNavigation.map((item) => (
                    <li key={item.name}>
                      <NavLink
                      to={item.href}
                      className="group flex items-center gap-x-3 rounded-md p-2 text-sm font-medium text-white hover:bg-blue-100 hover:text-blue-600 hover:duration-50 "
                    >
                      <item.icon
                        className="transition-all duration-300 h-6 w-6 shrink-0 group-hover:translate-x-2"
                        aria-hidden="true"
                      />
                      <span className="transition-all duration-300 group-hover:translate-x-2">
                        {item.name}
                      </span>
                      <ArrowRight className="h-6 w-6 shrink-0 transform -translate-x-12 group-hover:translate-x-0 opacity-0 group-hover:opacity-100 transition-all duration-300" />
                    </NavLink>
                  </li>
                ))}
                </ul>
              </li>
              {/* Subscription reminder for free trial users */}
              {userState?.user?.stripeSubscriptionStatus !== "active" && (
                <>
                  {userState?.user ? (
                    <div className="relative bg-blur-lg border border-blue-200 rounded-lg p-2 mt-auto">
                      <div className="absolute top-0 left-1/2 transform -translate-x-1/2 -mt-5 p-2 bg-blue-100 rounded-full">
                        <Info className="w-6 h-6 text-blue-500" />
                      </div>
                      <SubscriptionStatus open={TrialModalOpen} setOpen={setTrialModalOpen} />
                    </div>
                  ) : (
                    <></>
                  )}
                </>
              )}
              <li className="-mx-6 mt-auto">
                
                  {/* // User is authenticated, display profile information */}
                  <div className="flex justify-between items-center px-6 py-3 text-sm font-medium leading-6 text-white w-full">
                    <div className="flex items-center gap-x-4">
                      <img
                        className="h-9 w-9 rounded-md shadow-sm bg-gray-100"
                        // src={user.data.photoURL || ""}
                        src={'https://avatars.githubusercontent.com/u/104882015?v=4'}
                        alt="profile picture"
                      />
                      <div className="text-center sm:mt-0 sm:text-left align-content">
                        <p aria-hidden="true">{userState.user?.name}</p>
                        {/* <p aria-hidden="true">@{props.user?.username}</p> */}
                        <p
                          className="text-xs font-normal text-white"
                          aria-hidden="true"
                        >
                        {userState.user?.stripeSubscriptionStatus === 'active' ?  
                          <p>Premium</p>
                          : <>
                          <p>Free Trial</p>
                          </>
                        }
                        </p>
                      </div>
                    </div>
                    <span className="sr-only">Your profile</span>
                    <div
                      onClick={() => {
                         http.post('api/auth/logout', {}, true).then((data) => {
                          if (data.status === 200) {
                            window.location.reload();
                            return;
                          }
                          return data.json();
                         }).then((data) => {
                          if (data?.error) {
                            toast.error(data.error);
                            return;
                          }
                          // alert('from response')
                          // toast.error("Something went wrong")
                         }).catch((err) => {
                          alert('from catch')
                          toast.error('Something went wrong')
                         })
                      }}
                    >
                      <SignOut
                        className="h-6 w-6 text-white cursor-pointer hover:text-blue-500 hover:duration-300 hover:ease-in-out"
                        aria-hidden="true"
                      />
                    </div>
                  </div>
              </li>
            </ul>
          </nav>
        </div>
      </div>
      <div className="sticky top-0 z-40 flex items-center gap-x-6 bg-white px-4 py-4 shadow-sm sm:px-6 lg:hidden">
        <button
          type="button"
          className="-m-2.5 p-2.5 text-white lg:hidden"
          onClick={() => setSidebarOpen(true)}
        >
          <span className="sr-only">Open sidebar</span>
          <List className="h-6 w-6" aria-hidden="true" />
        </button>
        <div className="flex-1 text-sm font-medium leading-6 text-slate-900">
          Dashboard
        </div>
        <a href="#">
          <span className="sr-only">Your profile</span>
          <img
            className="h-8 w-8 rounded-md bg-gray-100"
            src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
            alt=""
          />
          <X className="h-6 w-6 text-black" aria-hidden="true" />
        </a>
      </div>
      <TrialModal open={TrialModalOpen} setOpen={setTrialModalOpen} />
    </div>
  );
};

export default Sidebar;