import { useEffect, useState } from "react";
import http from "../lib/http";
import { useSearchParams, useNavigate } from "react-router-dom";
import toast from 'react-hot-toast'
import { Loader } from "./Loader";
import { ThreeDots } from 'react-loader-spinner'
const Facebook = function () {

    const searchParams = useSearchParams();
    const navigate = useNavigate()
    console.log('fb rendered')
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        const token = searchParams[0].get('code');
        async function api() {
            try {
                setLoading(true)
                const response = await http.post('api/account/facebook/callback', { code: token }, true)
                const data = await response.json();

                if (data.error) {
                    alert(JSON.stringify({data}))
                    toast.error(data.error);
                    setTimeout(() => {
                        navigate('/', { replace: true })
                    }, 1500)
                }
                else {
                    toast.success("Sucessfully connected to Facebook");
                    navigate('/', {replace: true})
                }
                // navigate('/')
            } catch (error) {
                toast.error("Something went wrong");
                // setTimeout(() => {
                //     navigate('/', {replace: true})
                // }, 1500)
            } finally {
                setLoading(false);
            }
        }
        if (token) {
            api()
        }
        else {
            navigate('/login', {replace: true})
        }

    }, [])
    return (
        <div className="h-screen flex justify-center items-center">
            <ThreeDots
                visible={true}
                height="20"
                width="50"
                color="white"
                radius="12"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClass=""
            />
        </div>
    )
}


export default Facebook;

