class HttpClient{
    static instance = null
    constructor() {


        if (HttpClient.instance) {
            return HttpClient.instance;
        }
        console.log('new client instantiated')
        HttpClient.instance = this;
    }




    async get(url, credentials) {
        const response = await fetch(process.env.REACT_APP_server + url, {
            method: 'GET',
            headers: {
                'Content-type' : 'application/json'
            },
            ...(credentials === true && {mode: 'cors', credentials: 'include'})
        })
        if (response.status === 401 || response.status === 403) {
            // window.location.reload()
        }
        return response;
    }


    async post(url, body, credentials) {
        const response = await fetch(process.env.REACT_APP_server + url, {
            method: 'POST',
            headers: {
                'Content-type' : 'application/json'
            },
            ...(credentials === true && {mode: 'cors', credentials: 'include'}),
            body: JSON.stringify(body)
        })
        if (response.status === 401 || response.status === 403) {
            window.location.reload()
        }
        return response;
    }


    async put(url, body, credentials) {
        const response = await fetch(process.env.REACT_APP_server + url, {
            method: 'PUT',
            headers: {
                'Content-type' : 'application/json'
            },
            ...(credentials === true && {mode: 'cors', credentials: 'include'}),
        })
        if (response.status === 401 || response.status === 403) {
            window.location.reload()
        }
        return response;
    }

    async delete(url, body, credentials) {
        const response = await fetch(process.env.REACT_APP_server + url, {
            method: 'DELETE',
            headers: {
                'Content-type' : 'application/json'
            },
            ...(credentials === true && {mode: 'cors', credentials: 'include'}),
        })
        if (response.status === 401 || response.status === 403) {
            window.location.reload()
        }
        return response;
    }

    async patch(url, body, credentials) {
        const response = await fetch(process.env.REACT_APP_server + url, {
            method: 'PATCH',
            headers: {
                'Content-type' : 'application/json'
            },
            body: JSON.stringify(body),
            ...(credentials === true && {mode: 'cors', credentials: 'include'}),
        })
        if (response.status === 401 || response.status === 403) {
            window.location.reload()
        }
        return response;
    }
}


const http = new HttpClient();
export default http;