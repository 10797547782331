export const login_req = 'login_req'
export const login_suc = 'login_suc'
export const login_fail = 'login_fail'




export const load_req = 'load_req'
export const load_suc = 'load_suc'
export const load_fail = 'load_fail'



export const logout_req = 'logout_req'
export const logout_suc = 'logout_suc'
export const logout_fail = 'logout_fail'