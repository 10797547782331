import Sidebar from '../components/sidebar';
import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid'
import { SecondaryButton } from '../components/buttons';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}


const Folders = () => {
  return (
    <div>
      {/* Sidebar */}
      <div className="hidden md:flex w-72 md:flex-col md:fixed md:inset-0">
      <Sidebar />
      </div>

      <div className="flex flex-col flex-1 lg:pl-72">
        {/* Main content */}
        <main className="flex-1">
          <div className="px-4 py-10 sm:px-4 lg:px-4 lg:py-4 border border-gray-200 rounded-xl bg-white h-screen my-2">
          {/* Replace with your Main Content component */}


      <div className="overflow-hidden">
        <h2 className="sr-only" id="profile-overview-title">
          Profile Overview
        </h2>
          <div className="sm:flex sm:items-center sm:justify-between">
            <div className="sm:flex items-center sm:space-x-5">
              <div className="flex-shrink-0">
              </div>
              <div className="mt-4 text-center sm:mt-0 sm:pt-1 sm:text-left align-content">
                <p className="text-sm font-medium text-slate-600">Welcome back,</p>
                <p className="text-xl font-bold text-slate-900 sm:text-2xl">Name</p>
              </div>
            </div>
            <div className="mt-5 flex justify-center sm:mt-0">
            <SecondaryButton
        isDisabled={false}
        // clickHandler={#}
    >
        View Content
        </SecondaryButton>
        </div>
        </div>

        <div className="grid grid-cols-1 divide-y divide-gray-200 border-t border-gray-200 bg-gray-50 sm:grid-cols-3 sm:divide-x sm:divide-y-0">
        </div>
      </div>

    <div className="px-4 pt-14 sm:px-6 lg:px-4">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-slate-900">Hashtag monitoring</h1>
          <p className="mt-2 text-sm text-slate-700">
            A list of all the touch points you're using to monitor and capture user-generated content .
          </p>
        </div>
        {/* <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <button
            type="button"
            className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Add new hashtag
          </button>
        </div> */}
      </div>
      <div className="-mx-4 mt-8 sm:-mx-0">
        <table className="min-w-full divide-y divide-gray-300">
          <thead>
            <tr>
              <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-slate-900 sm:pl-0">
                Platform
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-slate-900 sm:table-cell"
              >
                Trigger
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-slate-900 lg:table-cell"
              >
                Monitoring
              </th>
              <th scope="col" className="relative py-3.5 pr-4 sm:pr-0">
                <span className="sr-only">Edit</span>
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
              <tr className="even:bg-gray-50">
              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-slate-900 sm:pl-3">
                    <div className="flex items-center">
                      <img src="./images/InstagramLogo.webp" alt="Instagram logo" className="w-5 h-5 mr-2" />
                      Instagram
                    </div>
                  </td>
                <td className="hidden whitespace-nowrap px-3 py-4 text-sm text-slate-500 sm:table-cell">
                  Hashtag
                </td>
                <td className="hidden whitespace-nowrap px-3 py-4 text-sm text-slate-500 lg:table-cell">
                <input
                  type="hashtag"
                  name="hashtag"
                  id="hashtag"
                  className="block max-w-2xl pl-4 rounded-md border-0 py-1.5 text-slate-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-slate-700 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  placeholder="DunderMifflin"
                />
                </td>
                <td className="whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                  <a href="#" className="text-indigo-600 hover:text-indigo-900">
                    Update<span className="sr-only">Update hashtag</span>
                  </a>
                </td>
              </tr>
          </tbody>
        </table>
      </div>
    </div>
      

          </div>
        </main>
      </div>
    </div>
  );
};

export default Folders;