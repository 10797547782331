import Sidebar from '../components/sidebar';
import React, { useEffect, useState } from 'react';
import Overview from '../components/overview';
import Onboarding from '../components/onboarding';
import { auth } from '../firebase';
import { FacebookAuthProvider, signInWithPopup, unlink } from 'firebase/auth';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { useSelector } from "react-redux";
import { Loader } from "../components/Loader";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Account = (props) => {

  const userState = useSelector(state => state.user);
  const [state, setUser] = useState();
  return (
    <div>
      {/* Sidebar */}
      {/* Static sidebar for desktop */}
      <div className="hidden md:flex md:w-72 md:flex-col md:fixed md:inset-0">
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className="flex flex-col flex-1 min-h-">
          {/* side bar shared component */}
          <Sidebar />
        </div>
      </div>
      {/* Main content */}
      <main className="flex flex-col flex-1 md:pl-[17rem] m-4">
        <div className="drop-shadow-sm border border-slate-100 rounded-2xl bg-white dashboard-height overflow-y-auto h-screen">
          {/* Replace with your Main Content component */}
          <div className={`pb-2`}>

            {userState.user != null && userState.user.account != null ? <Overview></Overview> : <Onboarding></Onboarding> }
          </div>
          {/* other home page content here / integration guide for slack*/}
        </div>
      </main>
    </div>

  )
}
export default Account;
