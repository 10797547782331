import React, { Component, useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// import PrivateRoute from './auth/privateRoute';
import Onboarding from './components/onboarding';
import Login from './auth/login';
import Signup from './auth/signup';
import authDetails from './auth/authDetails';
import Content from './pages/content';
import Folders from './pages/folders';
import { auth } from './firebase';
import Account from './pages/account';
import { Toaster, toast } from 'react-hot-toast';
import './index.css';
import { useDispatch, useSelector } from 'react-redux';
import { loadUser, loginAction, logout, visit } from './actions/userAction';
import {useNavigate} from 'react-router-dom'
import { Loader } from './components/Loader';
import Facebook from './components/facebook';



// const App = () => {
//   return (
//     <Router>
//       <AuthProvider>
//         <Routes>
//           <Route
//             path="/"
//             element={
//               <PrivateRoute>
//                 <Onboarding />
//               </PrivateRoute>
//             }
//           />
//           <Route
//             path="/content"
//             element={
//               <PrivateRoute>
//                 <ContentGrid />
//               </PrivateRoute>
//             }
//           />
//           <Route path="/signup" element={<Signup />} />
//           <Route path="/login" element={<Login />} />
//         </Routes>
//       </AuthProvider>
//     </Router>
//   );
// };


function App() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  const userState = useSelector(state => state.user);
  const dispatch = useDispatch();


  const [firebaseUser, setFirebaseUser] = useState(null);
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setFirebaseUser(user);
      } else {
        dispatch(loadUser("145"));
      }
    });
    return () => unsubscribe();
  }, [])
  useEffect(() => {
    if (firebaseUser?.uid) {
      // alert(auth.currentUser.uid)
      if (userState.visited !== true) {
        // alert("you just visited.....");
        // toast("You just visited");
        dispatch(loadUser(firebaseUser.uid));
      }
      else {
        // alert('you just logged in...')
        // toast("You just logged in")
        dispatch(visit(false));
      }
    }
  }, [firebaseUser])


  const navigate = useNavigate();
  useEffect(() => {
    if (userState.error) {
      navigate("/login")
    }
  }, [userState])
  useEffect(() => {
    dispatch(loadUser())
  }, [])
  // useEffect(() => {
  //   toast.success(JSON.stringify(userState));
  // }, [userState, dispatch])

  // useEffect(() => {
  //   alert('app ' + user?.hashtag?.key);
  // }, [user, loading])
  if (userState.loading) {
    return <div className='h-screen flex justify-center items-center'>
      <Loader color={'white'}/>
    </div>
  }
  console.log(userState);
  return (
    <div>
     
      {/* <button onClick={() => {
        toast("Success")
      }}>here is the button</button> */}
      {/* <Layout> */}
      <Routes>
        <Route path="/" element={<Account user={user} setUser={setUser} />} />
        <Route path="content" element={<Content user={user} loading={loading} />} />
        <Route path="folders" element={<Folders />} />
        <Route path="signup" element={<Signup />} />
        <Route path="login" element={<Login />} />
      </Routes>
      {/* </Layout> */}
    </div>

  );
}

export default App;
