const PrimaryButton = ({ children, clickHandler, disabled }) => {
  return (
    <button
    className="flex rounded-md bg-blue-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
      type="button"
      disabled={disabled}
      onClick={clickHandler}>
      <span>
        {children}
      </span>
    </button>
  );
};

const SecondaryButton = ({ children, clickHandler }) => {
  return (
    <button 
      className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
      type="button"
      onClick={clickHandler}>
    <span>
      {children}
    </span>
  </button>
  );
};

const RedButton = ({ children, clickHandler }) => {
  return (
    <button 
      className="rounded-md bg-red-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
      type="button"
      onClick={clickHandler}>
    <span>
      {children}
    </span>
  </button>
  );
};

const FacebookButton = ({ children, clickHandler }) => {
  return (
    <button 
        type="button"
        onClick={clickHandler}
        className="flex rounded-md bg-blue-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600">
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-facebook" viewBox="0 0 16 16">
            <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"/>
        </svg>
        <span class="text-sm pl-2 text-white">{children}</span>
    </button>
  );
};

export { PrimaryButton, SecondaryButton, RedButton, FacebookButton };