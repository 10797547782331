import React, { useRef, useEffect } from 'react';

const ProfilePicture = ({ name, size = 300, bgColor = '#DCDCDC', textColor = '#464646' }) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    if (canvasRef.current) {
        const canvas = canvasRef.current;
        const context = canvas.getContext('2d');
    
        // Set canvas size
        canvas.width = size;
        canvas.height = size;
    
        // Draw background
        context.fillStyle = bgColor;
        context.fillRect(0, 0, size, size);
    
        // Draw initials
        context.fillStyle = textColor;
        context.font = `${size / 3}px Arial`;
        context.textAlign = 'center';
        context.textBaseline = 'middle';
        const initials = name.split(' ').map(word => word[0].toUpperCase()).join('');
        context.fillText(initials, size / 2, size / 2);
    }
   
  }, [name, size, bgColor, textColor]);

  return <canvas ref={canvasRef} className='mx-auto h-16 w-16 rounded-lg shadow-sm'/>;
};

export default ProfilePicture;
