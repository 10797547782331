import { unlink } from "firebase/auth";
import { load_fail, load_req, load_suc } from "../constants";
import { auth } from "../firebase";
import toast from "react-hot-toast";
import http from "../lib/http";


//coupled logic of handling different error types within action itself to avoid state complexity
export const loginAction = function (user, access_token, err) {
    return async (dispatch) => {
        try {
            if (!user || !access_token) {
                dispatch({
                    type : load_fail,
                    payload : err ? err : "something went wrong here"
                })
            }
            dispatch({
                type: load_req,
            });

            const data = await fetch(process.env.REACT_APP_server + "api/auth/finish", {
                method: "POST",
                body: JSON.stringify({
                    user_id: user.providerData[0].uid,
                    uuid: user.uid,
                    displayName: user.providerData[0].displayName,
                    email: user.providerData[0].email,
                    access_token: access_token,
                }),
                headers: {
                    "Content-type": "application/json",
                },
            });

            const res = await data.json();
            // alert(JSON.stringify(res))
            if (data && res.success && res.data) {
                dispatch({
                    type: load_suc,
                    payload: res.data
                })
                // alert('success');
            }
            else {
                // alert('fail');
                dispatch({
                    type: load_fail,
                    payload: res.message
                });

                await auth.signOut();
                if (data.status === 400) {
                    toast.error(res.message ? res.message : "Firebase error");
                }
                else  {
                    toast.error(
                        "Need business account with instagram , Please choose one of the current linked business account, and pages"
                    );
                }
                if (user) {
                    const id = user.providerData[0].uid
                    const value = await unlink(user, user?.providerData[0]?.providerId);
                    await value.delete();
                    fetch(
                        `https://graph.facebook.com/v11.0/${id}/permissions?access_token=${access_token}`,
                        {
                            method: "DELETE",
                        }
                    )
                        .then((data) => {
                            console.log("deleted app");
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                }
               
            }
       
            
        }
        catch(err) {
            dispatch({type : load_fail, payload : 'Something failed'});
        }
    } 
}


 export const loadUser = function(id) {
    return async (dispatch) => {
        try {
            dispatch({
                type : load_req
            });

            const response = await http.get('api/auth/refresh', true);


            const data = await response.json();

            if (data.error) {
                dispatch({
                    type: load_fail,
                    payload: {
                        error: data.error
                    }
                })
                return;
            }

            console.log(data);
            dispatch({
                type: load_suc,
                payload: {
                    user: data.data
                }
            })
        } catch (error) {
            dispatch({
                type : load_fail,
                payload : {
                    error: "Something went wrong"
                }
            })
        }
    }
 }


 export const visit = function (value) {
    return async (dispatch) => {
        dispatch({
            type : 'visit',
            payload : value ? true : false
        })
    } 
 }


 export const logout = function() {
    return async (dispatch) => {
        dispatch({
            type : 'logout'
        })
    }
 }


 export function hashtagUpdate(hashtag) {
    return async (dispatch) => {
        dispatch({
            type: 'hashtag_update',
            payload: hashtag
        })
    }
 }
