import { useSelector } from "react-redux";
import { SecondaryButton } from "./buttons";
import toast from "react-hot-toast";

export const SubscriptionStatus = function (props) {

    const userState = useSelector(state => state.user);
    function cancelSubscription() {
        if (userState && userState?.data?.uuid) {
            fetch(process.env.REACT_APP_server + "api/subscription/cancel", {method: 'PUT', headers: {
                authorization: 'Bearer ' + userState?.data.uuid
            }}).then((data) => {
                return data.json()
            }).then((res) => {
                console.log(res);
            }).catch((err) => {
                console.log(err);
            });
            toast.success("Subscription will be deleted at the end of current billing, you won't be charged from now")
        } 
    }
    if (userState?.user?.stripeSubscriptionStatus) {
        if (userState?.user?.stripeSubscriptionStatus === "trialing") {
            return (<>
            <h3 className="text-sm font-medium text-white mb-2 mt-6 text-center">
              {userState?.data?.posts ? userState?.data?.posts : 0}/25 posts saved
            </h3>
            <p className="text-white text-sm font-normal leading-5 text-center">
              Please consider subscribing to prevent losing access.
            </p>
            <div className="flex items-center justify-center mt-3 pb-2">
              <SecondaryButton
                isDisabled={false}
                clickHandler={() => {
                //   alert(JSON.stringify(user.data));
                  props.setOpen(true)
                }}
              >
                Subscribe
              </SecondaryButton>
            </div>
          </>)
        }
        else if (userState?.user?.stripeSubscriptionStatus === "active") {
            return (<>
                <p className="text-white text-sm font-normal leading-5 text-center mt-5">
                   Billing date : 
                </p>
                <div className="flex items-center justify-center mt-3 pb-2">
                  <SecondaryButton
                    isDisabled={false}
                    clickHandler={() => {
                      cancelSubscription()
                    }}
                  >
                    Cancel Subscription
                  </SecondaryButton>
                </div>
              </>)
        }
        else{
            return (<>
                <p className="text-white text-sm font-normal leading-5 text-center mt-6">
                  Subscription Expired! Please subscribe to continue services
                </p>
                <div className="flex items-center justify-center mt-5 pb-2">
                  <SecondaryButton
                    isDisabled={false}
                    clickHandler={() => {
                    //   alert(JSON.stringify(user.data));
                      props.setOpen(true)
                    }}
                  >
                    Subscribe
                  </SecondaryButton>
                </div>
              </>)
        }
    }
    else {
        return (
            <>
            </>
        )
    }
};
