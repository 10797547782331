import { load_req, load_fail, load_suc, logout_req, logout_suc, logout_fail } from "../constants";

export const userReducer = (state = {loading : false,  user : null, error : null}, action) => {
    switch(action.type) {
        case load_req:
            return {
                ...state,
                loading: true,
                user: null,
                error: null
            }
        case load_suc:
            return {
                ...state,
                loading : false,
                user : action.payload.user,
                error : null
            }

        case load_fail:
            return {
                ...state,
                loading : false,
                user : null,
                error : action.payload.error
            }

        case logout_req: 
            return {
                ...state,
                loading: true,
                error: null
            }
        case logout_suc:
            return {
                ...state,
                loading: false,
                user: null,
                error: null
            }
        case logout_fail:
            return {
                ...state,
                loading: false,
                error: 'Something went wrong'
            }
        case 'hashtag_update':
            return {
                ...state,
                user: {
                    ...state.user,
                    account : {
                        ...state.user.account,
                        hashtag: action.payload
                    }
                }
            }
        default:
            return state;
    }
}