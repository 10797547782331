import { ThreeCircles } from "react-loader-spinner";

export const Loader = function ({color}) {
  return (
    <div className="flex justify-center items-center h-full">
    <ThreeCircles
      height="100"
      width="100"
      color={color || "#2563EB"}
      wrapperStyle={{}}
      wrapperClass=""
      visible={true}
      ariaLabel="three-circles-rotating"
      outerCircleColor=""
      innerCircleColor=""
      middleCircleColor=""
    />
    </div>
  );
};
