import { Link } from "react-router-dom";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { useEffect, useState } from "react";
import { auth } from '../firebase';
import http from "../lib/http";
import { ThreeDots } from "react-loader-spinner";
import {toast} from 'react-hot-toast'
import {useNavigate} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import { loadUser } from "../actions/userAction";
const Signup = () => {

  const [cred, setCred] = useState({
    name: '',
    email: '',
    password: ''
  })

  const [loading, setLoading] = useState(false);

  function handleChange(e) {

    setCred((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value
      }
    })
  }

  const userState = useSelector(state => state.user);
  useEffect(() => {
    if (userState.user) {
      navigate("/", {replace: true})
    }
  }, [userState])

  const navigate = useNavigate();
  const dispatch = useDispatch();
  async function handleSubmit(e) {
    e.preventDefault()

    try {
      setLoading(true)
      const resp = await http.post(`api/auth/signup`, { ...cred }, true)
      console.log(resp.status);
      const data = await resp.json();
      if (data.error) {
        toast.error(data.error);
        return
      }
      navigate("/")
      dispatch(loadUser())
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false)
    }



  }
  return (
    <>
      <div className="flex min-h-screen justify-center items-center bg-[#fbf9f3]">
        <div className="relative w-full max-w-lg">
          <div className="absolute top-0 -left-4 w-10/12 h-96 bg-purple-300 rounded-full filter blur-xl opacity-70 animate-blob"></div>
          <div className="absolute top-0 -right-4 w-10/12 h-96 bg-indigo-300 rounded-full filter blur-xl opacity-70 animate-blob animation-delay-2000"></div>
          <div className="absolute -bottom-8 left-20 w-10/12 h-96 bg-pink-300 rounded-full filter blur-xl opacity-70 animate-blob animation-delay-4000"></div>
          <div className="relative mt-5 sm:mx-auto sm:w-full sm:max-w-[480px]">
            <div className="bg-gradient-to-b from-white via-white to-white/75 opacity-90 px-6 py-12 stripe-shadow rounded-2xl border sm:px-12">
              <div className="flex justify-center">
                <img
                  className="h-5 w-auto inverted-logo-black"
                  src="/images/YugeLogo02.png"
                  alt="Yuge Logo"
                />
              </div>
              <div class="flex items-center justify-center">
                <h2 className="my-4 text-2xl flex items-center text-center font-semibold leading-9 tracking-tight text-slate-900">
                  Join Yuge for Free
                </h2>
                <img class="h-6 w-6 ml-2" src="/images/party-popper.png" alt="Confetti Emoji" />
              </div>
              <div className="mt-2">
                <a
                  href={`${process.env.REACT_APP_server}api/auth/google`}
                  className="flex w-full items-center justify-center gap-3 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:ring-transparent"
                >
                  <svg className="h-5 w-5" aria-hidden="true" viewBox="0 0 24 24">
                    <path
                      d="M12.0003 4.75C13.7703 4.75 15.3553 5.36002 16.6053 6.54998L20.0303 3.125C17.9502 1.19 15.2353 0 12.0003 0C7.31028 0 3.25527 2.69 1.28027 6.60998L5.27028 9.70498C6.21525 6.86002 8.87028 4.75 12.0003 4.75Z"
                      fill="#EA4335"
                    />
                    <path
                      d="M23.49 12.275C23.49 11.49 23.415 10.73 23.3 10H12V14.51H18.47C18.18 15.99 17.34 17.25 16.08 18.1L19.945 21.1C22.2 19.01 23.49 15.92 23.49 12.275Z"
                      fill="#4285F4"
                    />
                    <path
                      d="M5.26498 14.2949C5.02498 13.5699 4.88501 12.7999 4.88501 11.9999C4.88501 11.1999 5.01998 10.4299 5.26498 9.7049L1.275 6.60986C0.46 8.22986 0 10.0599 0 11.9999C0 13.9399 0.46 15.7699 1.28 17.3899L5.26498 14.2949Z"
                      fill="#FBBC05"
                    />
                    <path
                      d="M12.0004 24.0001C15.2404 24.0001 17.9654 22.935 19.9454 21.095L16.0804 18.095C15.0054 18.82 13.6204 19.245 12.0004 19.245C8.8704 19.245 6.21537 17.135 5.2654 14.29L1.27539 17.385C3.25539 21.31 7.3104 24.0001 12.0004 24.0001Z"
                      fill="#34A853"
                    />
                  </svg>
                  <span className="text-sm font-semibold leading-6">Google</span>
                </a>
              </div>
              <div className="relative mt-6">
                <div className="absolute inset-0 flex items-center" aria-hidden="true">
                  <div className="w-full border-t border-gray-200" />
                </div>
                <div className="relative flex justify-center text-sm font-medium leading-6">
                  <span className="px-6 bg-white text-gray-900">Or continue with</span>
                </div>
              </div>
              <form className="space-y-6" action="#" method="POST" onSubmit={handleSubmit}>
                <div>
                  <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900">
                    Full name
                  </label>
                  <div className="mt-2">
                    <input
                      id="name"
                      placeholder="michael stark"
                      name="name"
                      type="text"
                      autoComplete={false}
                      value={cred.name}
                      onChange={handleChange}
                      required
                      className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-slate-700 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
                <div>
                  <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                    Work email address
                  </label>
                  <div className="mt-2">
                    <input
                      id="email"
                      placeholder="michael@dundermifflin.com"
                      name="email"
                      type="email"
                      autoComplete="email"
                      value={cred.email}
                      onChange={handleChange}
                      required
                      className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-slate-700 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>

                <div>
                  <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                    Password
                  </label>
                  <div className="mt-2">
                    <input
                      id="password"
                      placeholder="ryanhoward"
                      name="password"
                      type="password"
                      autoComplete="current-password"
                      value={cred.password}
                      onChange={handleChange}
                      required
                      className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-slate-700 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>

                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <input
                      id="remember-me"
                      name="remember-me"
                      type="checkbox"
                      className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600"
                    />
                    <label htmlFor="remember-me" className="ml-3 block text-sm leading-6 text-gray-900">
                      Remember me
                    </label>
                  </div>

                  <div className="text-sm leading-6">
                    <a href="#" className="font-semibold text-blue-600 hover:text-blue-500">
                      Forgot password?
                    </a>
                  </div>
                </div>

                <div>
                  <button
                    type="submit"
                    className="flex w-full justify-center rounded-md bg-blue-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 items-center gap-3" disabled={loading}>
                    {loading && <ThreeDots
                      visible={true}
                      height="14"
                      width="30"
                      color="white"
                      radius="9"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClass=""
                    />} Create Account
                  </button>
                </div>
              </form>
              <p className="mt-4 text-sm text-center leading-6 text-slate-500">
                Already have an account?{' '}
                <Link to={"/login"} className="font-semibold text-blue-600 hover:text-blue-500">Log in here</Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default Signup;
