import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Link } from "react-router-dom";
import { GreenButton, PrimaryButton } from "./buttons";
import toast from "react-hot-toast";
import { loadStripe } from "@stripe/stripe-js";
import { auth } from "../firebase";
import http from "../lib/http";
import {ThreeDots} from 'react-loader-spinner'

const TrialModal = ({ open, setOpen }) => {
  const cancelButtonRef = useRef(null);
  const [loading, setLoading] = useState(false);
  async function checkoutSession() {
    try {
      setLoading(true)
      const stripe = await loadStripe(process.env.REACT_APP_stripe_key)

      const response = await http.get('api/billing/create-checkout-session', true);
      const data = await response.json();
      if (!data.success) {
        toast.error(data.error);
      }
      else {
        const result = await stripe.redirectToCheckout({
          sessionId: data.id
        });
        if (result.error) {
          toast.error("Internal Server error");
        }
      }
    } catch (error) {
      console.log(error);
      toast.error("Interal Server Error")
    } finally {
      setLoading(false)
    }
  }
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 transition-opacity bg-gray-900 bg-opacity-25 opacity-100 backdrop-blur-sm" />
        </Transition.Child>
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-2xl border bg-white pb-6 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm">
                <div>
                  <div class="mx-auto mt-1 flex items-center justify-center">
                    <img class="h-6 mr-2 inverted-logo-black" src="/images/YugeLogo02.png" alt="Yuge Logo" />
                  </div>
                  <div class="mt-3 text-center sm:mt-5 px-6 border-b pb-6">
                    <h3 class="font-bold text-xl leading-6 text-gray-900" id="modal-title">Continue Saving UGC</h3>
                    <div class="mt-2">
                      <p class="text-md text-gray-500">Upgrade to continue monitoring and capturing social posts. Cancel anytime.</p>
                    </div>
                  </div>
                </div>
                <div class="rounded-md mx-6 bg-fuchsia-50 p-4 my-6">
                  <div class="flex items-center">
                    <img class="h-7 w-7 mr-2" src="/images/party-popper.png" alt="Confetti Emoji" />
                    <p class="text-md font-medium text-fuchsia-950 ml-3">You're eligible for beta pricing!</p>
                  </div>
                </div>
                <div class="mt-3 text-md text-center sm:mt-5 px-6">
                  <ul class="list-none m-0 p-0">
                    <li class="flex items-center mb-2">
                      <svg class="w-5 h-5 text-green-500 mr-2 flex-shrink-0" viewBox="0 0 90 90" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="45" cy="45" r="45" fill="#28c937" />
                        <path fill="#ffffff" d="M 38.478 64.5 c -0.01 0 -0.02 0 -0.029 0 c -1.3 -0.009 -2.533 -0.579 -3.381 -1.563 L 21.59 47.284 c -1.622 -1.883 -1.41 -4.725 0.474 -6.347 c 1.884 -1.621 4.725 -1.409 6.347 0.474 l 10.112 11.744 L 61.629 27.02 c 1.645 -1.862 4.489 -2.037 6.352 -0.391 c 1.862 1.646 2.037 4.49 0.391 6.352 l -26.521 30 C 40.995 63.947 39.767 64.5 38.478 64.5 z" />
                      </svg>
                      One Instagram Account & Hashtag
                    </li>
                    <li class="flex items-center mb-2">
                      <svg class="w-5 h-5 text-green-500 mr-2 flex-shrink-0" viewBox="0 0 90 90" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="45" cy="45" r="45" fill="#28c937" />
                        <path fill="#ffffff" d="M 38.478 64.5 c -0.01 0 -0.02 0 -0.029 0 c -1.3 -0.009 -2.533 -0.579 -3.381 -1.563 L 21.59 47.284 c -1.622 -1.883 -1.41 -4.725 0.474 -6.347 c 1.884 -1.621 4.725 -1.409 6.347 0.474 l 10.112 11.744 L 61.629 27.02 c 1.645 -1.862 4.489 -2.037 6.352 -0.391 c 1.862 1.646 2.037 4.49 0.391 6.352 l -26.521 30 C 40.995 63.947 39.767 64.5 38.478 64.5 z" />
                      </svg>
                      Capture Unlimited Posts
                    </li>
                    <li class="flex items-center mb-2">
                      <svg class="w-5 h-5 text-green-500 mr-2 flex-shrink-0" viewBox="0 0 90 90" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="45" cy="45" r="45" fill="#28c937" />
                        <path fill="#ffffff" d="M 38.478 64.5 c -0.01 0 -0.02 0 -0.029 0 c -1.3 -0.009 -2.533 -0.579 -3.381 -1.563 L 21.59 47.284 c -1.622 -1.883 -1.41 -4.725 0.474 -6.347 c 1.884 -1.621 4.725 -1.409 6.347 0.474 l 10.112 11.744 L 61.629 27.02 c 1.645 -1.862 4.489 -2.037 6.352 -0.391 c 1.862 1.646 2.037 4.49 0.391 6.352 l -26.521 30 C 40.995 63.947 39.767 64.5 38.478 64.5 z" />
                      </svg>
                      Premium Support & Account Manager
                    </li>
                    <li class="flex items-center mb-2">
                      <svg class="w-5 h-5 text-green-500 mr-2 flex-shrink-0" viewBox="0 0 90 90" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="45" cy="45" r="45" fill="#28c937" />
                        <path fill="#ffffff" d="M 38.478 64.5 c -0.01 0 -0.02 0 -0.029 0 c -1.3 -0.009 -2.533 -0.579 -3.381 -1.563 L 21.59 47.284 c -1.622 -1.883 -1.41 -4.725 0.474 -6.347 c 1.884 -1.621 4.725 -1.409 6.347 0.474 l 10.112 11.744 L 61.629 27.02 c 1.645 -1.862 4.489 -2.037 6.352 -0.391 c 1.862 1.646 2.037 4.49 0.391 6.352 l -26.521 30 C 40.995 63.947 39.767 64.5 38.478 64.5 z" />
                      </svg>
                      Access to All Future Features
                    </li>
                  </ul>
                </div>
                <label class="relative block rounded-lg border bg-white mx-6 px-6 py-4 mt-6 shadow-sm focus:outline-none sm:flex sm:justify-between">
                  <span class="flex items-center">
                    <span class="flex flex-col text-sm">
                      <span id="server-size-1-label" class="font-medium text-gray-900">Monthly</span>
                      <span id="server-size-1-description-0" class="text-gray-500">
                        <span class="block sm:inline">Cancel anytime</span>
                        <span class="hidden sm:mx-1 sm:inline" aria-hidden="true">&middot;</span>
                        <span class="block sm:inline">Beta pricing</span>
                      </span>
                    </span>
                  </span>
                  <span id="server-size-1-description-1" class="mt-2 flex text-sm sm:ml-4 sm:mt-0 sm:flex-col sm:text-right">
                    <span class="font-medium text-gray-900">$20</span>
                    <span class="ml-1 text-gray-500 sm:ml-0">/mo</span>
                  </span>
                  <span class="pointer-events-none absolute -inset-px rounded-lg border- " aria-hidden="true"></span>
                </label>

                <div class="mt-6 border-t">
                  <div class="mt-6 mx-6">
                    <button
                      type="button"
                      className="inline-flex justify-center items-center gap-4 w-full pl-4 py-2 text-base font-medium text-white bg-blue-500 border border-transparent rounded-md shadow-sm hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:text-sm"
                      onClick={checkoutSession}
                    >
                       {loading && <ThreeDots
                      visible={true}
                      height="14"
                      width="30"
                      color="white"
                      radius="9"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClass=""
                    />}
                      Upgrade Now
                    </button>
                  </div>
                  <div className="mt-3 text-sm text-center text-slate-600">
                    <button
                      className="underline font-normal"
                      onClick={() =>
                        window.$crisp.push(["do", "chat:open"])
                      }
                    >
                      Contact Live Chat
                    </button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default TrialModal;





