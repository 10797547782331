import Sidebar from "../components/sidebar";
import { Fragment, useEffect, useState } from "react";
import { DotsThreeCircleVertical, DownloadSimple, InstagramLogo, Trash } from "@phosphor-icons/react";
import { Menu, Transition } from "@headlessui/react";
import Filter from "../components/filter";
import { useNavigate, useNavigation } from "react-router-dom";
import DownloadLink from "../components/Downloadable";
import Masonry from "react-masonry-css";
import { auth } from "../firebase";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import { Loader } from "../components/Loader";
import http from "../lib/http";


function classNames(...classes) {
	return classes.filter(Boolean).join(" ");
}


const Content = (props) => {
	const [taggedPosts, setTaggedPosts] = useState();
	const [filter, setFilter] = useState({
		username: "",
		photos: false,
		videos: false,
		newest: true,
	});
	console.log(props);
	const [cols, setCols] = useState(getResponsiveCols()); // State for responsive columns
	const dispatch = useDispatch();
	const userState = useSelector((state) => state.user);
	const navigate = useNavigate();
	const [contentLoading, setContentLoading] = useState(false);
	// const [DeleteModalOpen, setDeleteModalOpen] = useState(false);

	// Determine the number of columns based on the window width
	function getResponsiveCols() {
		const screenWidth = window.innerWidth;

		if (screenWidth >= 1920) { // Monitor screens
			return 6;
		} else if (screenWidth >= 960) { // Desktop screens
			return 4;
		} else { // Smaller than half desktop size
			return 2;
		}
	}


	useEffect(() => {
		const handleResize = () => {
			setCols(getResponsiveCols());
		};

		window.addEventListener('resize', handleResize);

		// Clean up
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	useEffect(() => {
		// toast.success(user.data?.displayName);
		if (userState.error) {
			navigate("/login")
			return;
		}

		async function fetchPosts() {
			try {
				setContentLoading(true)
				const response = await http.get(`api/ugc`, true)
				if (response.status === 401) {
					window.location.reload();
					return;
				}
				const data = await response.json();

				if (data.error) {
					toast.error(data.error)
				}
				else {
					setTaggedPosts(data.data)
				}
			} catch (error) {
				console.log(error);
				toast.error(error?.message || "Something went wrong") 
			} finally {
				setContentLoading(false)
			}
		}
		fetchPosts()
	}, [userState, filter]);
	return (
		<div>
			{/* Sidebar */}
			{/* Static sidebar for desktop */}
			<div className="hidden md:flex md:w-72 md:flex-col md:fixed md:inset-0">
				{/* Sidebar component, swap this element with another sidebar if you like */}
				<div className="flex flex-col flex-1 min-h-0">
					{/* side bar shared component */}
					<Sidebar />
				</div>
			</div>
			{/* Main content */}
			<main className="flex flex-col flex-1 md:pl-[17rem] m-4">
				<div className="drop-shadow-sm border border-slate-100 rounded-2xl bg-white dashboard-height h-screen overflow-y-auto">
					{/* Replace with your Main Content component */}
						<>
							<div className={contentLoading ? `h-full` : ''}>
								<Filter filter={filter} setFilter={setFilter} />
								{contentLoading && <Loader></Loader>}
							</div>
							{!contentLoading && taggedPosts && (
								<>
									{/* <Masonry breakpointCols={4}> */}
									<Masonry breakpointCols={cols}>  {/* Use the responsive column count */}
										{/* <ul
              role="list"
              className="grid grid-cols-2 gap-x-4 gap-y-4 sm:grid-cols-3 sm:gap-x-4 lg:grid-cols-4 xl:gap-x-4"
            > */}
										{taggedPosts &&
											taggedPosts.map((post) => {
												if (post) {
													return (
														<div key={post.media_url} className="relative p-1.5">
															<div className="group relative aspect-h-7 aspect-w-10 block w-full overflow-hidden rounded-lg bg-white stripe-shadow">
																<div className="relative">
																	<div className="absolute top-3 left-0 right-0 z-20">
																		<div className="flex items-center justify-between px-4">
																			<div className="flex items-center space-x-2">
																				<p className="text-sm font-medium text-white">
																					{post.username || "#" + userState.user.account.hashtag}
																				</p>
																				<span className="text-xs font-medium text-white">
																					{moment().diff(
																						moment(post.timeStamp),
																						"days"
																					) != 0
																						? moment().diff(
																							moment(post.timeStamp),
																							"days"
																						) + "d"
																						: moment().diff(
																							moment(post.timeStamp),
																							"hours"
																						) + "h"}
																				</span>
																			</div>
																			<Menu as="div" className="relative">
																				<Menu.Button className="-m-1.5 flex items-center p-1.5">
																					<span className="sr-only">
																						Open user menu
																					</span>
																					<DotsThreeCircleVertical
																						className="ml-2 h-5 w-5 mb-2 text-white"
																						aria-hidden="true"
																					/>
																				</Menu.Button>
																				<Transition
																					as={Fragment}
																					enter="transition ease-out duration-100"
																					enterFrom="transform opacity-0 scale-95"
																					enterTo="transform opacity-100 scale-100"
																					leave="transition ease-in duration-75"
																					leaveFrom="transform opacity-100 scale-100"
																					leaveTo="transform opacity-0 scale-95"
																				>
																					<Menu.Items className="absolute right-2 mt-2.5 w-32 origin-top-right border border-gray-200 rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none cursor-pointer">
																						<Menu.Item key={"Download"}>
																							{({ active }) => (
																								<a
																									className={classNames(
																										active ? "bg-gray-50" : "",
																										"flex items-center px-3 py-1 text-sm leading-6 text-slate-900 cursor-pointer"
																									)}
																									href={post.media_url}
																									download={"filename"}
																								>
																									<DownloadSimple className="mr-3 h-4 w-4 text-slate-500" />
																									Download
																								</a>
																							)}
																						</Menu.Item>
																						<Menu.Item key={"View"}>
																							{({ active }) => (
																								<a
																									className={classNames(
																										active ? "bg-gray-50" : "",
																										"flex items-center px-3 py-1 text-sm leading-6 text-slate-900 cursor-pointer"
																									)}
																									href={post.permalink}
																									target="_blank"
																								>
																									<InstagramLogo className="mr-3 h-4 w-4 text-slate-500" />
																									View post
																								</a>
																							)}
																						</Menu.Item>
																						<Menu.Item key={"delete"}>
																							{({ active }) => (
																								<a
																									className={classNames(
																										active ? "bg-gray-50" : "",
																										"flex items-center px-3 py-1 text-sm leading-6 text-slate-900 cursor-pointer"
																									)}
																									delete={"filename"}
																								>
																									<Trash className="mr-3 h-4 w-4 text-slate-500" />
																									Delete
																								</a>
																							)}
																						</Menu.Item>
																					</Menu.Items>
																				</Transition>
																			</Menu>
																		</div>
																	</div>
																	<div className="relative">
																		{post.media_type === "VIDEO" ? (
																			<>
																				<video
																					src={post.media_url}
																					className="object-cover rounded-md"
																					controls
																				></video>
																				<div className="absolute top-0 left-0 right-0 bottom-2/3 bg-gradient-to-b from-black via-transparent to-transparent rounded-md"></div>
																			</>
																		) : (
																			<>
																				<img
																					src={post.media_url}
																					alt={post.media_url}
																					className="pointer-events-none object-cover rounded-md"
																					loading="eager"
																				/>
																				<div className="absolute inset-0 bg-gradient-to-b from-black via-transparent to-transparent rounded-md"></div></>


																		)}

																	</div>
																</div>
															</div>
														</div>

													);
												}
											})}
										{/* </ul> */}
									</Masonry>
									{taggedPosts.length === 0 && (
										<div className="mx-auto mt-28 text-center max-w-md">
											<h3 className="mt-2 text-lg font-semibold text-slate-900">
												Your brand has not been tagged or mentioned
											</h3>
											<p className="mt-1 text-sm text-slate-500">
												Posts, reels, and stories will appear here. Please
												check back later.
											</p>
											<div className="mt-6">
												<div className="flex justify-center">
													<img
														className="h-60 w-auto rounded-lg drop-shadow-lg"
														src="/images/waiting.gif"
														alt="Waiting gif"
													/>
												</div>
											</div>
										</div>
									)}
								</>
							)}
						</>
					

					{/* <ul role="list" className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8">
  {files.map((file) => (
    <div key={file.source} className="relative">
      <div className="group relative aspect-h-7 aspect-w-10 block w-full overflow-hidden rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 focus-within:ring-offset-gray-100">
        <div className="border border-gray-300 rounded-lg overflow-hidden">
          <div className="flex items-center justify-between p-2">
            <div className="flex items-center space-x-2">
              <img
              src={file.source}
              alt="profile picture"
              className="rounded-md w-6 h-6"
            />
              <p className="text-sm font-medium text-slate-900">{file.title}</p>
            </div>
            <EllipsisVerticalIcon className="h-6 w-6 text-slate-900" aria-hidden="true" />
          </div>

          <div className="relative">
            <img
              src={file.source}
              alt="UGC asset"
              className="pointer-events-none object-cover group-hover:opacity-75"
            />
            <div className="absolute inset-0 bg-gradient-to-t from-black via-transparent to-transparent rounded-lg" />
          </div>

          <span className="absolute top-12 right-2 text-xs font-medium bg-gray-900 bg-opacity-75 text-white p-1 rounded-sm">
            00:24
          </span>

          <div className="flex items-center justify-between mt-2">
            <span className="text-xs font-medium text-slate-800">view post</span>
            <span className="text-xs font-medium text-slate-800">3d ago</span>
          </div>
        </div>

        <button type="button" className="absolute inset-0 focus:outline-none">
          <span className="sr-only">View details for {file.title}</span>
        </button>
      </div>
    </div>
  ))}
</ul> */}

					{/* 
          <ul role="list" className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8">
            {files.map((file) => (
              <div className="bg-gray-600 rounded-lg">
              <div key={file.source} className="relative">
                <div className="group relative aspect-h-7 p-4 aspect-w-10 block w-full overflow-hidden rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 focus-within:ring-offset-gray-100">
                  <img src={file.source} alt="" className="pointer-events-none object-cover group-hover:opacity-75" />
                  <div className="absolute inset-0 bg-gradient-to-t from-black via-transparent to-transparent"></div>
                  <button type="button" className="absolute inset-0 focus:outline-none flex flex-col items-start justify-end p-4">
                    <span className="sr-only">View details for {file.title}</span>
                    <span className="absolute bottom-10 left-4 truncate text-md font-medium text-white">
                    {file.title}
                    </span>
                    <span className="absolute bottom-4 left-4 text-sm font-medium text-slate-300">
                    {file.size}
                  </span>
                    <span className="absolute top-4 left-4 text-xs font-medium bg-black bg-opacity-75 text-white p-1 rounded-md">
                    00:24
                  </span>
                  <span className="absolute bottom-4 right-4 text-xs font-medium text-slate-300">
                  3 days ago
                  </span>
                  <span className="absolute top-4 right-4">
                    <EllipsisHorizontalIcon className="h-6 w-6 text-white" aria-hidden="true" />
                  </span>
                  </button>
                </div>
              </div>
            </div>
            ))}
          </ul> */}
				</div>
			</main>
		</div>
	);
};

export default Content;
