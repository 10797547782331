import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from "react-hot-toast";
import { auth } from "../firebase";
import { PrimaryButton, SecondaryButton } from "./buttons";
import { PencilSimple, Warning, } from "@phosphor-icons/react";
import TrialModal from './trialModal';
import { useDispatch, useSelector } from "react-redux";
import { loadUser } from "../actions/userAction";
import { SubscriptionStatusBanner } from "./SubscriptionStatusBanner";
import ProfilePicture from './ProfilePicture'
import http from '../lib/http';
import { hashtagUpdate } from '../actions/userAction'
const user = {
	name: "@username",
	imageUrl:
		"https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
};
const stats = [
	{ label: "Total UGC", value: 9 },
	{ label: "Videos", value: 9 },
	{ label: "Images", value: 9 },
];

const Overview = (props) => {

	const [TrialModalOpen, setTrialModalOpen] = useState(false)
	const userState = useSelector(state => state.user);
	const [hashtag, setHashtag] = useState(userState.user.account.hashtag || "");



	useEffect(() => {
		if (userState.user && userState.user.account) {
			setHashtag(userState.user.account.hashtag || "")
		}
	}, [userState])
	const dispatch = useDispatch();
	// useEffect(() => {
	//   toast(props.user.hashtag)
	// }, [props.user])
	const navigate = useNavigate();
	const handleButtonClick = () => {
		navigate('/content');
	};


	async function updateHashtag(empty) {
		try {
			
			if (hashtag === userState.user.account.hashtag && !empty) {
				
				toast.remove()
				toast.success("Hashtag updated succesfully!")
				return;
			}
			if (hashtag.length === 0 && !empty) {
				toast.remove()
				toast.error("Hashtag field cannot be empty...")
				return;
			}
			const response = await http.patch('api/user/hashtag', {
				type: hashtag.length === 0 || empty ? 'REMOVE' : 'ADD',
				...(hashtag.length !== 0 && { key: hashtag })
			}, true)

			const data = await response.json();

			if (data.error) {
				toast.error(data.error)
			}
			else {
				//dispatch update hashtag
				if (empty) {
					toast.success("Hashtag removed successfully!")
					dispatch(hashtagUpdate(''))
				}
				else {
					toast.success('Hashtag updated successfully')
					dispatch(hashtagUpdate(hashtag))

				}
			}
		} catch (error) {
			toast.error("Something went wrong")
			console.log(error)
		}
	}
	return (
		<div className="overflow-hidden">
			<h2 className="sr-only" id="profile-overview-title">
				Profile Overview
			</h2>
			<div className="bg-white">
				<div className="sm:flex sm:items-center sm:justify-between px-4 py-2">
					<div className="sm:flex items-center sm:space-x-5">
						<div className="flex-shrink-0">
							<ProfilePicture name={userState.user.name} />
							{/* <img
                className="mx-auto h-16 w-16 rounded-lg shadow-sm"
                src={auth.currentUser ? auth.currentUser.photoURL : user}
                alt="profile picture"
              /> */}
						</div>
						<div className="mt-4 text-center sm:mt-0 sm:pt-1 sm:text-left align-content">
							<p className="text-sm font-medium text-slate-600">Welcome back,</p>
							<p className="text-xl font-bold text-slate-700 sm:text-2xl">
								@{userState.user.account.username}
							</p>
							<p>Followers: {userState.user.account.followers || 0} |  Following: {userState.user.account.following || 0}</p>

						</div>
					</div>
					<div className="mt-5 flex justify-center sm:mt-0">
						<PrimaryButton
							clickHandler={handleButtonClick}
						>
							View UGC
						</PrimaryButton>
					</div>
				</div>
			</div>

			{/* stats for counting UGC */}
			{/* <div className="grid grid-cols-1 divide-y divide-gray-200 border-y border-gray-200 bg-gray-50 sm:grid-cols-3 sm:divide-x sm:divide-y-0">
        {stats.map((stat) => (
          <div
            key={stat.label}
            className="py-4 text-center text-sm font-medium"
          >
            <span className="text-slate-900">{stat.value}</span>{" "}
            <span className="text-slate-600">{stat.label}</span>
          </div>
        ))}
      </div> */}

			{/* section for subscription */}
			<SubscriptionStatusBanner open={TrialModalOpen} setOpen={setTrialModalOpen} />


			{/* section for viewing and updating hashtag */}
			<div className="sm:flex sm:items-center">
				<div className="sm:flex-auto border-b border-gray-200 px-4 pb-4">
					<h1 className="text-base font-medium leading-6 text-slate-900">
						Account monitoring
					</h1>
					<p className="mt-2 text-sm text-slate-700">
						A list of all the Instagram triggers you're using to monitor and
						capture user-generated content.
					</p>
				</div>
				{/* <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
              <button
                type="button"
                className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Add new hashtag
              </button>
            </div> */}
			</div>
			<div className="-mx-4 mt-4 sm:-mx-0 px-4">
				<table className="min-w-full divide-y divide-gray-300">
					<thead>
						<tr>
							<th
								scope="col"
								className="py-3.5 pl-4 pr-3 text-left text-sm font-medium text-slate-900 sm:pl-0"
							>
								Platform
							</th>
							<th
								scope="col"
								className="hidden px-3 py-3.5 text-left text-sm font-medium text-slate-900 sm:table-cell"
							>
								Trigger
							</th>
							<th
								scope="col"
								className="hidden px-3 py-3.5 text-left text-sm font-medium text-slate-900 lg:table-cell"
							>
								Value
							</th>
							<th scope="col" className="relative py-3.5 pr-4 sm:pr-0">
								<span className="sr-only">Edit</span>
							</th>
						</tr>
					</thead>
					<tbody className="divide-y divide-gray-200 bg-white">
						<tr className="even:bg-gray-50">
							<td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-slate-900 sm:pl-3">
								<div className="flex items-center">
									<img
										src="./images/InstagramLogo.webp"
										alt="Instagram logo"
										className="w-5 h-5 mr-2"
									/>
									Instagram
								</div>
							</td>
							<td className="hidden whitespace-nowrap px-3 py-4 text-sm text-slate-500 sm:table-cell">
								Hashtag
							</td>
							<td className="hidden whitespace-nowrap px-3 py-4 text-sm text-slate-500 lg:table-cell">
								<div className="flex items-center">
									<PencilSimple className="h-4 w-4 mr-2" />
									<input
										type="hashtag"
										name="hashtag"
										id="hashtag"
										className="peer block border-0 pl-2 py-1.5 -ml-2 text-gray-500 text-sm focus:ring-0 sm:text-sm sm:leading-6"
										placeholder="Add hashtag"
										value={hashtag}
										onChange={(e) => {
											setHashtag(e.target.value);
										}}
									/>
								</div>
							</td>
							<td className="whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0 flex gap-3 justify-end">
								<SecondaryButton className="text-blue-600 hover:text-blue-900" clickHandler={() => {
									updateHashtag()
								}}>

									Update
								</SecondaryButton>
								<PrimaryButton className="text-blue-600 hover:text-blue-900" clickHandler={() => {
									updateHashtag(true)
								}}>Remove</PrimaryButton>
							</td>
						</tr>
						<tr className="even:bg-gray-50">
							<td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-slate-900 sm:pl-3">
								<div className="flex items-center">
									<img
										src="./images/InstagramLogo.webp"
										alt="Instagram logo"
										className="w-5 h-5 mr-2"
									/>
									Instagram
								</div>
							</td>
							<td className="hidden whitespace-nowrap px-3 py-4 text-sm text-slate-500 sm:table-cell">
								Account
							</td>
							<td className="hidden whitespace-nowrap px-3 py-4 text-sm text-slate-500 lg:table-cell">
								@{userState.user.account.username}
							</td>
							<td className="whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
								{/* add onClick event for disconnect */}
								{/* <button className="text-blue-600 hover:text-blue-900" > 
                    Disconnect
                  </button> */}
							</td>


						</tr>
						<tr>
							<td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-slate-900 sm:pl-3">
								<div className="flex items-center">
									<img
										src="./images/FacebookLogo.png"
										alt="Instagram logo"
										className="w-5 h-5 mr-2"
									/>
									Facebook
								</div>
							</td>
							<td className="hidden whitespace-nowrap px-3 py-4 text-sm text-slate-500 sm:table-cell">
								Page
							</td>
							<td className="hidden whitespace-nowrap px-3 py-4 text-sm text-slate-500 lg:table-cell">
								<div className='flex flex-col'>
									<div className='text-md'>{userState.user.account.page_name}</div>
									<div className='text-[8px]'>{"Category: " + props.user?.page_category}</div>

								</div>

							</td>
							<td className="whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
								{/* add onClick event for disconnect */}
								{/* <button className="text-blue-600 hover:text-blue-900" > 
                    Disconnect
                  </button> */}
							</td>
						</tr>
					</tbody>
				</table>
			</div>
			<TrialModal open={TrialModalOpen} setOpen={setTrialModalOpen} />
		</div>
	);
};
export default Overview;
