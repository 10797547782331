import { Fragment, useState } from "react";
import Sidebar from "./sidebar";
import Header from "./header";
import React from "react";
import { PlusIcon } from "@heroicons/react/20/solid";
import { auth } from "../firebase";
import { FacebookAuthProvider, signInWithPopup, unlink } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { FacebookButton, PrimaryButton } from "./buttons";
import { toast } from "react-hot-toast";
import { useDispatch } from "react-redux";
import { loginAction, visit } from "../actions/userAction";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Onboarding = (props) => {

  const dispatch = useDispatch();
  const provider = new FacebookAuthProvider();
  provider.addScope("email");
  provider.addScope("instagram_basic")
  provider.addScope("business_management");
  provider.addScope("pages_manage_metadata");
  provider.addScope("pages_read_engagement");
  provider.addScope("pages_show_list")
  provider.addScope("instagram_manage_comments")

  const navigate = useNavigate();
  function handleSignIn() {
    dispatch(visit(true));
    signInWithPopup(auth, provider)
      .then((result) => {
        // The signed-in user info.
        const user = result.user;
        // This gives you a Facebook Access Token. You can use it to access the Facebook API.
        const credential = FacebookAuthProvider.credentialFromResult(result);
        const accessToken = credential.accessToken;
        console.log(accessToken);
        dispatch(loginAction(user, accessToken));
      })
      .catch((error) => {
        // Handle Errors here.
        if (error && error.code == "auth/user-cancelled") {
          dispatch(loginAction(null, null, "Make sure, your facebook account has a page, and is connected to instagram business account"))
        }
        dispatch(loginAction())
        console.log(error);
        // ...
      });
  }
  return (
    <>
      <main className="flex items-center justify-center min-h-screen">
        <div className="px-4 sm:px-6 lg:px-8">
          {/* main dashboard content*/}
          <div className="px-4 sm:px-6 lg:px-8">
            <div className="text-center">
              <img
                src="/images/InstagramLogo.webp"
                alt="Instagram logo"
                className="mx-auto h-12 w-12 text-slate-700"
                aria-hidden="true"
              />
              <h3 className="mt-4 text-xl font-semibold text-slate-900">
                Connect your Instagram page
              </h3>
              <p className="mt-1 mx-auto max-w-2xl text-sm leading-6 text-slate-500">
                In order to detect mentions and tagged posts, you need to
                connect your Instagram page using your Facebook Business page
                it's linked with.
              </p>
              <div className="mt-2">
                <div className="flex items-center justify-center p-4">
                  {/* <PrimaryButton
        isDisabled={false}
        clickHandler={handleSignIn}
    >
        Connect Instagram Account
        </PrimaryButton> */}
                  <a href={`${process.env.REACT_APP_server}api/account/facebook`}>
                    <FacebookButton
                      isDisabled={false}
                      clickHandler={handleSignIn}
                    >
                      Connect with Facebook
                    </FacebookButton>
                  </a>
                </div>
                <div className="flex justify-center">
                  <p className="mt-2 text-sm text-slate-500">
                    Not working?&nbsp;
                  </p>
                  <a
                    className="mt-2 text-sm text-slate-500 cursor-pointer underline"
                    onClick={() => window.$crisp.push(["do", "chat:open"])}
                  >
                    Contact live chat
                  </a>
                </div>
              </div>
            </div>

            <div className="mt-12">
              <nav className="mx-auto max-w-7xl" aria-label="Progress">
                <ol
                  role="list"
                  className="overflow-hidden rounded-md flex items-center justify-center"
                >
                  <li className="relative overflow-hidden">
                    <div className={classNames("overflow-hidden")}>
                      <span
                        className="absolute left-0 top-0 h-full w-1 bg-transparent group-hover:bg-gray-200 lg:bottom-0 lg:top-auto h-1 w-full"
                        aria-hidden="true"
                      />
                      <div
                        className={classNames(
                          "flex items-center flex-col px-6 py-5 text-sm font-medium"
                        )}
                      >
                        <span className="flex-shrink-0">
                          <span className="flex h-10 w-10 items-center justify-center rounded-full border-2 border-gray-300">
                            <span className="text-slate-500">1</span>
                          </span>
                        </span>
                        <span className="mt-2 mx-auto max-w-sm text-center">
                          <span className="text-sm font-medium text-slate-500">
                            Continue as the profile with admin access to your
                            brands Facebook Page.
                          </span>
                        </span>
                      </div>
                    </div>
                  </li>

                  <li className="relative overflow-hidden">
                    <div className={classNames("overflow-hidden")}>
                      <span
                        className="absolute left-0 top-0 h-full w-1 bg-transparent group-hover:bg-gray-200 lg:bottom-0 lg:top-auto h-1 w-full"
                        aria-hidden="true"
                      />
                      <div
                        className={classNames(
                          "flex items-center flex-col px-6 py-5 text-sm font-medium"
                        )}
                      >
                        <span className="flex-shrink-0">
                          <span className="flex h-10 w-10 items-center justify-center rounded-full border-2 border-gray-300">
                            <span className="text-slate-500">2</span>
                          </span>
                        </span>
                        <span className="mt-2 mx-auto max-w-sm text-center">
                          <span className="text-sm font-medium text-slate-500">
                            Select your Instagram Business Profile you'd like to
                            monitor.
                          </span>
                        </span>
                      </div>
                    </div>
                  </li>

                  <li className="relative overflow-hidden">
                    <div className={classNames("overflow-hidden")}>
                      <span
                        className="absolute left-0 top-0 h-full w-1 bg-transparent group-hover:bg-gray-200 lg:bottom-0 lg:top-auto h-1 w-full"
                        aria-hidden="true"
                      />
                      <div
                        className={classNames(
                          "flex items-center flex-col px-6 py-5 text-sm font-medium"
                        )}
                      >
                        <span className="flex-shrink-0">
                          <span className="flex h-10 w-10 items-center justify-center rounded-full border-2 border-gray-300">
                            <span className="text-slate-500">3</span>
                          </span>
                        </span>
                        <span className="mt-2 mx-auto max-w-sm text-center">
                          <span className="text-sm font-medium text-slate-500">
                            Select the Facebook Page your Instagram business
                            account is linked to.
                          </span>
                        </span>
                      </div>
                    </div>
                  </li>

                  <li className="relative overflow-hidden">
                    <div className={classNames("overflow-hidden")}>
                      <span
                        className="absolute left-0 top-0 h-full w-1 bg-transparent group-hover:bg-gray-200 lg:bottom-0 lg:top-auto h-1 w-full"
                        aria-hidden="true"
                      />
                      <div
                        className={classNames(
                          "flex items-center flex-col px-6 py-5 text-sm font-medium"
                        )}
                      >
                        <span className="flex-shrink-0">
                          <span className="flex h-10 w-10 items-center justify-center rounded-full border-2 border-gray-300">
                            <span className="text-slate-500">4</span>
                          </span>
                        </span>
                        <span className="mt-2 mx-auto max-w-sm text-center">
                          <span className="text-sm font-medium text-slate-500 ">
                            Allow all of the pre-selected permissions to finish
                            onboarding.
                          </span>
                        </span>
                      </div>
                    </div>
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};
export default Onboarding;
